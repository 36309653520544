import {Component, ComponentFactoryResolver, Inject, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {ScriptUtility} from "./shared/utility/script-utility";
import {environment} from "../environments/environment";
import {DynamicComponent} from "./shared/components/dynamic.component";
import {AppStateService} from "./shared/services/app-state.service";
import {PageComponentService} from "./shared/services/page-component.service";
import whatInput from 'what-input'
import {ActivatedRoute} from "@angular/router";
import {GoogleTagManager} from "./shared/utility/google-tag-manager";
import {AppRestService} from "./shared/services/app-rest.service";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";

// important for css styles attribute
export const whatInputScript = whatInput;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  readonly PAGE_SCRIPT_URL = '/system-res/js/controllers/base-page/base-page.min.js?v=1';
  readonly PORTAL_SCRIPT_URL = '/system-res/js/portal-base-page.js?v=3';

  header: SafeHtml;
  footer: SafeHtml;
  isContentLoaded: boolean = false;
  isSecondContentLoaded: boolean = false;
  public mediaLinkDomain: string;

  constructor(@Inject(DOCUMENT)
              private document: Document,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private componentService: PageComponentService,
              private configService: AppStateService,
              public restService: AppRestService,
              private componentFactory: ComponentFactoryResolver,
              private httpClient: HttpClient,
              private sanitizer: DomSanitizer,
              private titleService: Title,
              private metaService: Meta) {
  }

  ngOnInit(): void {
    if (this.restService.isVirgin()) {
      let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      let favIconShort: HTMLLinkElement = document.querySelector('#appIconShort');
      let favIconApple: HTMLLinkElement = document.querySelector('#appIconApple');
      favIcon.href = "assets/images/favicon/favicon-virginmobile.png";
      favIconShort.href = "assets/images/favicon/favicon-virginmobile.png";
      favIconApple.href = "assets/images/favicon/favicon-virginmobile.png";
      this.titleService.setTitle($localize`:@@metaTitleVirgin:Doładuj konto i Internet | Virgin Mobile`);
      this.metaService.addTag({
        name: 'title',
        content: $localize`:@@metaTitleVirgin:Doładuj konto i Internet | Virgin Mobile`
      });
      this.metaService.addTag({
        name: 'description',
        content: $localize`:@@metaDescriptionVirgin:Doładuj kartę wybraną kwotą od 5 do 300 zł. Szybko i łatwo, kiedy i gdzie chcesz! Ciesz się rozmowami, SMS-ami oraz Internetem od Virgin Mobile.`
      });
      this.loadScript("https://cdn.cookielaw.org/consent/" + this.restService.config.virginCookieScriptCode + "OtAutoBlock.js", null, null);
      this.loadScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", "UTF-8", this.restService.config.virginCookieScriptCode);
      this.isContentLoaded = true;
    } else {
      this.titleService.setTitle($localize`:@@metaTitlePlay:Doładuj konto i Internet | Play`);
      this.metaService.addTag({name: 'title', content: $localize`:@@metaTitlePlay:Doładuj konto i Internet | Play`})
      this.metaService.addTag({
        name: 'description',
        content: $localize`:@@metaDescriptionPlay:Doładuj kartę wybraną kwotą od 5 do 300 zł. Szybko i łatwo, kiedy i gdzie chcesz! Ciesz się rozmowami, SMS-ami oraz Internetem od Play.`
      })
      this.loadScript("https://cdn.cookielaw.org/consent/" + this.restService.config.playCookiesScriptCode + "OtAutoBlock.js", null, null);
      this.loadScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", "UTF-8", this.restService.config.playCookiesScriptCode);
      this.isContentLoaded = true;
    }
    this.setupLiteOrFullVersion();
    this.configService.getRechargesConfig()
      .then((config) => {
        if (this.restService.isVirgin()) {
          this.setDynamicStyle("/assets/css/virgin.css");
          this.setDynamicStyle("/assets/css/vm.css");
          this.isSecondContentLoaded = true;
        } else {
          this.setDynamicStyle("/assets/css/play.css");
          this.isSecondContentLoaded = true;
        }
      });
    this.mediaLinkDomain = this.restService.config.mediaLinkDomain;
    console.log(this.mediaLinkDomain)
  }

  private loadScript(url, charset, data) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    if (charset != null) {
      node.charset = 'utf-8';
    }
    node.setAttribute("data-domain-script", data)
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setDynamicStyle(cssURL: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = `${cssURL}`;
    head.appendChild(style);
  }

  private setupLiteOrFullVersion() {
    const queryParamsSnapshot = new URLSearchParams(window.location.search);
    const zeroToken = queryParamsSnapshot.get('token');
    if (zeroToken) {
      this.restService.setLiteVersion();
    } else {
      if (environment.production) {
        ScriptUtility.appendHeadScriptWithBody(this.renderer, this.document.head, GoogleTagManager.GTM_SCRIPT_CONTENT);
        ScriptUtility.appendBodyNoScriptWithBody(this.renderer, this.document.body, GoogleTagManager.GTM_NOSCRIPT_CONTENT);
      }
    }
  }

  private createChildComponent(containerRef: ViewContainerRef, htmlElementSrc: HTMLElement): Observable<any> {
    const factory = this.componentFactory.resolveComponentFactory(DynamicComponent);
    const componentRef = containerRef.createComponent(factory);
    componentRef.instance.appendChildHtml(htmlElementSrc);
    return componentRef.instance.afterLoadEvent.asObservable();
  }

  public changeLanguage(code) {
    if (window.location.href.indexOf(code) > -1) {
      return window.location.href;
    } else if (window.location.href.indexOf("/pl/") > -1) {
      return window.location.href.replace("/pl/", code);
    } else if (window.location.href.indexOf("/uk/") > -1) {
      return window.location.href.replace("/uk/", code);
    } else if (window.location.href.indexOf("/en/") > -1) {
      return window.location.href.replace("/en/", code);
    }
  }
  public isAllContentLoaded():boolean{
     return this.isContentLoaded && this.isSecondContentLoaded;
  }
}
